import { Flex, Grid, Text } from "@theme-ui/components";
import React from "react";
import AppButton from "../layout/app-button";
import ColumnContainer from "../layout/column-container";

export const SmallTeaser = ({
  label,
  to = "/",
  title,
  subtitle,
  cta = "View",
}) => (
  <Flex
    sx={{
      flexDirection: "column",
      py: [4],
      px: [3, 4],
      bg: "offWhite",
      borderRadius: 30,
      alignItems: "flex-start",
      "& > *": {
        maxWidth: ["80%", null, "90%", "65%"],
      },
    }}
  >
    <Text variant="label" sx={{ color: "blue" }}>
      {label}
    </Text>
    <Text variant="subtitle" sx={{ fontWeight: "bold" }}>
      {title}
    </Text>
    <Text variant="paragraph" sx={{ mb: 3 }}>
      {subtitle}
    </Text>
    <AppButton
      to={to}
      colors={{
        default: { background: "blue", text: "white" },
      }}
      icon="cal"
    >
      {cta}
    </AppButton>
  </Flex>
);

export const SmallTeaserGrid = ({ children }) => {
  return (
    <ColumnContainer>
      <Grid sx={{}} gap={[4, 4, 3, 4]} columns={[1, null, 2]}>
        {children}
      </Grid>
    </ColumnContainer>
  );
};

export default SmallTeaserGrid;
