import { graphql } from "gatsby";
import React from "react";
import { Box, Grid } from "theme-ui";
import Layout from "../components/layout";
import Scroller from "../components/layout/scroller";
import ThemeableHeader from "../components/layout/themeable-header";
import ClosedTeaser from "../components/page-blocks/closed-teaser";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import ReusableLatestContent from "../components/page-blocks/reusable-latest-content";
import {
  SmallTeaser,
  SmallTeaserGrid,
} from "../components/page-blocks/small-teaser-grid";
import TwitterCallToAction from "../components/page-blocks/twitter-cta";
import Seo from "../components/seo";

const ScrollerContainer = ({ children }) => (
  <Box
    sx={{
      scrollSnapAlign: "center",
      flexShrink: 0,
      width: "100%",
      float: "right",
    }}
  >
    {children}
  </Box>
);

const eventbriteNormalizer = (event) => {
  return {
    isFree: event?.is_free,
    description: event.description.text,
    listed: event.listed,
    logo: event.logo ? event.logo.url : null,
    name: event.name.text,
    onlineEvent: event.online_event,
    privacy: event.privacy_setting,
    publishedDate: event.publishedDate,
    startDate: event.start.local,
    status: event.status,
    summary: event.summary,
    url: event.url,
  };
};

const EventsPage = ({
  data: {
    q: { events },
  },
}) => {
  const openEvents = events.filter((event) => event.status === "live");
  const closedEvents = events.filter((event) => event.status === "completed");
  const latestEvent = openEvents[0]
    ? eventbriteNormalizer(openEvents[0])
    : null;
  return (
    <Layout>
      <Seo
        title="Events"
        description="Events taking place in the world of qualifications and assessment held by the AQi and other organisations from around the world."
      />
      <ThemeableHeader
        title={"Events"}
        active="/events"
        pageIntroduction="Events taking place in the world of qualifications and assessment held by the AQi and other organisations from around the world."
      />
      <Grid as="section" columns={1} gap={[4, 5, 6]} sx={{ my: [4, 5, 6] }}>
        {/* <ColumnContainer>
          <Text as="h1" variant="heading">
            Events are coming soon.
          </Text>
          <pre>{JSON.stringify(events, null, 2)}</pre>
        </ColumnContainer> */}
        {openEvents.length > 0 && (
          <ClosedTeaser
            color="blue"
            label={latestEvent.startDate}
            to={latestEvent.url}
            title={latestEvent.name}
            subtitle={latestEvent.summary}
            src={latestEvent.logo}
            cta="View Event"
            externalImage={true}
          />
        )}
        {closedEvents.length > 0 && (
          <Scroller title="Past events">
            <ScrollerContainer>
              <SmallTeaserGrid>
                {closedEvents
                  .map((event) => {
                    return eventbriteNormalizer(event);
                  })
                  .map((event) => {
                    return (
                      <SmallTeaser
                        label={event.startDate}
                        to={event.url}
                        title={event.name}
                        subtitle={event.summary.split(".")[0] + "."}
                        cta="View Event"
                      />
                    );
                  })}
              </SmallTeaserGrid>
            </ScrollerContainer>
          </Scroller>
        )}
        <ReusableLatestContent />
        <TwitterCallToAction />
        <NewsletterSignup />
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  {
    q: allEventbriteEvents(filter: { status: { in: ["live", "completed"] } }) {
      events: nodes {
        description {
          text
        }
        start {
          local(formatString: "ddd, LL")
          timezone
        }
        is_free
        published
        status
        privacy_setting
        summary
        url
        online_event
        listed
        logo {
          url
        }
        name {
          text
        }
      }
    }
  }
`;

export default EventsPage;
